<template>

    <div>
        <notifications group="alerts" position="bottom right"/>
        <notifications group="notifications" position="top left" style="top: 57px !important;" />
    </div>

</template>

<script>
    export default {
        name: 'Xero-Alerts',
        mounted() {
            this.checkStatuses();
            this.checkDialogs();
            this.checkErrors();
            this.checkError();
            this.checkSuccess();
            this.checkWarning();

            this.checkNotifications();
        },
        data() {
            return {
                activeStatuses: false,
                activeErrors: false,
                activeSuccess: false,
                activeError: false,
                activeWarning: false,
                activeNotification: false,
            };
        },
        computed: {
            watchError: function () {
                return this.$store.state.error;
            },
            watchErrors: function () {
                return this.$store.state.errors;
            },
            watchStatuses: function () {
                return this.$store.state.statuses;
            },
            watchDialogs: function () {
                return this.$store.state.dialogs;
            },
            watchSuccess: function () {
                return this.$store.state.success;
            },
            watchWarning: function () {
                return this.$store.state.warning;
            },
            watchNotification: function () {
                return this.$store.state.notification;
            },
        },
        watch: {
            watchError(newError, oldError) {
                if (newError) {
                    this.checkError();
                }
            },
            watchErrors(newErrors, oldErrors) {
                if (newErrors) {
                    this.checkErrors();
                }
            },
            watchStatuses(newStatuses, oldStatuses) {
                if (newStatuses) {
                    this.checkStatuses();
                }
            },
            watchDialogs(newDialogs, oldDialogs) {
                if (newDialogs) {
                    this.checkDialogs();
                }
            },
            watchSuccess(newSuccess, oldSuccess) {
                if (newSuccess) {
                    this.checkSuccess();
                }
            },
            watchWarning(newWarning, oldWarning) {
                if (newWarning) {
                    this.checkWarning();
                }
            },
            watchNotification(newNotification, oldNotification) {
                if (newNotification) {
                    this.checkNotifications();
                }
            },
        },
        methods: {
            clearStatuses() {
                this.$store.commit('statuses', null);
            },
            clearDialogs() {
                this.$store.commit('dialogs', null);
            },
            clearErrors() {
                this.$store.commit('errors', []);
            },
            clearSuccess() {
                this.$store.commit('success', null);
            },
            clearError() {
                this.$store.commit('error', null);
            },
            clearWarning() {
                this.$store.commit('warning', null);
            },
            clearNotification() {
                this.$store.commit('notification', null);
            },

            checkStatuses() {
                if (!this.$isIterable(this.$store.state.statuses) || Object.keys(this.$store.state.statuses).length <= 0) {
                    this.clearStatuses();
                    return;
                }

                this.activeStatuses = !!this.$store.state.statuses.length || !!Object.keys(this.$store.state.statuses).length;
                if (this.activeStatuses) {
                    this.pushNewStatuses(this.$store.state.statuses);

                    this.clearStatuses();
                }
            },
            checkDialogs() {
                if (!this.$isIterable(this.$store.state.dialogs) || Object.keys(this.$store.state.dialogs).length <= 0) {
                    this.clearDialogs();
                    return;
                }

                this.activeDialogs = !!this.$store.state.dialogs.length || !!Object.keys(this.$store.state.dialogs).length;
                if (this.activeDialogs) {
                    this.pushNewDialogs(this.$store.state.dialogs);

                    this.clearStatuses();
                }
            },
            checkErrors() {
                if (!this.$isIterable(this.$store.state.errors)) {
                    this.$store.commit('error', this.$store.state.errors);
                    this.$store.commit('errors', []);
                    return;
                }

                this.activeErrors = !!this.$store.state.errors.length;
                if (this.activeErrors) {
                    this.pushNewErrors(this.$store.state.errors);

                    this.clearErrors();
                }
            },
            checkError() {
                this.activeError = !!this.$store.state.error;
                if (this.activeError) {
                    this.createMessage('error', this.$store.state.error, 15000);
                    this.clearError();
                }
            },
            checkSuccess() {
                this.activeSuccess = !!this.$store.state.success;
                if (this.activeSuccess) {
                    this.createMessage('success', this.$store.state.success, 5000);
                    this.clearSuccess();
                }
            },
            checkWarning() {
                this.activeWarning = !!this.$store.state.warning;
                if (this.activeWarning) {
                    this.createMessage('warning', this.$store.state.warning, 10000);
                    this.clearWarning();
                }
            },
            checkNotifications() {
                this.activeNotification = !!this.$store.state.notification;
                if (this.activeNotification) {
                    this.createNotification(this.$store.state.notification, 15000);
                    this.clearNotification();
                }
            },

            pushNewErrors(errors) {
                if (this.$isIterable(errors)) {
                    errors.forEach((e) => {
                        if (Array.isArray(e)) {
                            this.pushNewErrors(e);
                        } else {
                            this.createMessage('error', e, 15000);
                        }
                    });
                } else {
                    this.createMessage('error', errors, 15000);
                }
            },
            pushNewStatuses(statuses) {
                if (this.$isIterable(statuses)) {
                    Object.keys(statuses).forEach((s) => {
                        if(statuses[s] === true)
                            this.createMessage('info', this.$capitalize(s), 15000);
                        else
                            this.createMessage('info', 'Not '+this.$capitalize(s), 15000);
                    });
                } else {
                    this.createMessage('info', statuses, 15000);
                }
            },
            pushNewDialogs(dialogs) {
                if (this.$isIterable(dialogs)) {
                    Object.keys(dialogs).forEach((d) => {
                        let data = JSON.parse(dialogs[d]);
                        this.$modal.show('dialog', {
                            title: data.title || '',
                            text: data.text || '',
                            buttons: [
                                {
                                    title: data.button || 'OK'
                                }
                            ]
                        });
                    });
                }
            },

            createMessage(type, text, duration) {
                this.$notify({
                    group: 'alerts',
                    type: type,
                    text: text,
                    duration: duration,
                });
            },
            createNotification(notification, duration) {
                this.$notify({
                    group: 'notifications',
                    type: 'xerocraft-notification',
                    text: `
                        <p class="is-capitalized has-text-weight-bold">${notification.header}</p>

                        <div class="is-size-7 has-margins-05 has-margins-tb">
                            <div class="content">${notification.body}</div>
                        </div>
                    `,
                    duration: duration,
                });
            },
        },
    }
</script>

<style lang="scss">
    .xerocraft-notification {
        & a {
            color: white !important;
            font-weight: bold;
        }
    }
</style>
