<template>

    <a :class="classes + ' fav-link fav-link-' + type" :href=" href ">
        <!-- type should be one of these = 'block', 'inline-block', 'flex', or 'inline' -->

        <slot></slot>

        <span v-if=" $containsThis($store.state.userFavLinks, $slots.default[0].text)" @click.prevent="$refs['FavLinkForm_'+_uid].submit()" title="Add to drop down menu" class="fav-link-span is-hidden-tablet has-text-danger"><i class="fas fa-minus-circle"></i></span>
        <span v-else @click.prevent="$refs['FavLinkForm_'+_uid].submit()" title="Add to drop down menu" class="fav-link-span has-text-grey is-hidden-tablet"><i class="fas fa-plus-circle"></i></span>
        <form :ref="'FavLinkForm_'+_uid" v-if=" $store.state.userLoggedIn " class="fav-link-form" action="/fav-link" method="post" enctype="multipart/form-data">
            <input type="hidden" name="_token" :value="$store.state.csrfToken">
            <input type="hidden" name="title" :value="$slots.default[0].text">
            <input type="hidden" name="url" :value="href">
        </form>
    </a>

</template>

<script>
export default {
    name: 'Fav-Link',
    props: {
        classes: {
            type: String,
            required: false,
            default: ''
        },
        type: {
            type: String,
            required: false,
            default: 'right',
            validator: function (value) {
                return ['right', 'inline'].indexOf(value) !== -1;
            },
        },
        href: {
            type: String,
            required: true
        },
    }
}
</script>

<style scoped lang="scss">

.fav-link-form {
    display: none;
}

body a:hover .is-hidden-tablet {
    display: inline-block !important;
}

body .fav-link:hover span.fav-link-span {
    display: inline-block;
}

.fav-link-right {
    position: relative;

    & span.fav-link-span {
        position: absolute;
        right: 5px;
    }
}

</style>
