<template>
    <div>
        <div class="first-footer">

            <div class="content">
                <h3 class="has-text-centered has-text-dark">Sponsors & Partners</h3>
            </div>
            <div class="columns is-mobile is-multiline is-centered">
                <div class="column is-narrow" v-for="sponsor in sponsorArray">
                    <a class="icon sponsor" target="_blank" :href="sponsor.url">
                        <img :src="sponsor.logo" :alt="sponsor.title + ' Logo'">
                    </a>
                </div>
            </div>

            <div class="footer-map">
                <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=xerocraft&t=&z=14&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </div>
        </div>
        <footer class="footer is-dark has-text-light">

            <div class="columns">
                <div class="column can-grow" style="flex-basis: 30%">
                    <div class="level">
                        <div class="level-left">
                            <div class="level-item">
                                <h4 class="has-text-light title is-4">Xerocraft</h4>
                            </div>
                        </div>
                        <div class="level-right">
                            <div class="level-item">
                                <div>
                                    <a title="Visit our Facebook page" class="icon is-medium is-primary-on-hover has-text-light" href="https://www.facebook.com/Xerocraft"><i class="fab fa-2x fa-facebook"></i></a>
                                    <a title="Visit our Twitter page" class="icon is-medium is-primary-on-hover has-text-light" href="https://twitter.com/xerocraft"><i class="fab fa-2x fa-twitter"></i></a>
                                    <a title="Visit our Youtube channel" class="icon is-medium is-primary-on-hover has-text-light" href="https://www.youtube.com/user/xerocrafthacks"><i class="fab fa-2x fa-youtube"></i></a>
                                    <a title="Visit our Instagram account" class="icon is-medium is-primary-on-hover has-text-light" href="https://www.instagram.com/xerocraft/"><i class="fab fa-2x fa-instagram"></i></a>
                                    <a title="View all of our social media platforms" class="icon is-medium is-primary-on-hover has-text-primary" @click.stop="$modal.show('all-social-media')"><i class="fa-2x fa-solid fa-caret-down"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="has-text-grey-light is-visible-mobile has-text-centered-mobile">
                        <p><i>Where people and ideas come together</i></p>
                    </div>
                    <div class="has-text-grey-light is-hidden-mobile my-4">
                        <p>We are a collection of scientists, engineers, tradesmen, artists, or any other hobby enthusiast,
                            but
                            we
                            almost all share one thing in common: curiosity.</p>
                        <p>We collaborate, hypothesize, learn, teach, create, and socialize. We attempt to hold frequent
                            workshops
                            teaching anything from basic Arduino programming, to how to extract DNA, to how to use a welder
                            or
                            3D-printer, to how to build a coffee table. </p>
                    </div>
                </div>
                <div class="is-divider-vertical"></div>
                <div class="column can-shrink">
                    <div class="content">
                        <h2 class="has-text-light content">Learn More</h2>
                    </div>
                    <div class="menu">
                        <ul class="menu-list">
                            <li><a href="/pages/covid-19-rules-and-recommendations">Covid-19</a></li>
                            <li><a href="/pages/safety-information">Safety Information</a></li>
                            <li><a href="/pages/xerocraft-re-opening-what-you-need-to-know">Re-Opening! FYI</a></li>
                            <li><a href="/threads">Forum / Discord</a></li>
                            <li><a href="/news">News & Announcements</a></li>
                            <li><a href="/steering-committee-meetings">Steering Committee Meetings</a></li>
                        </ul>
                    </div>
                </div>
                <div class="is-divider-vertical"></div>
                <div class="column can-shrink">
                    <div class="content">
                        <h2 class="has-text-light content">Need Help?</h2>
                    </div>
                    <div class="menu">
                        <ul class="menu-list">
                            <li><a href="/pages/contact-us">Contact Us</a></li>
                            <li><a href="/terms">Terms of Use</a></li>
                            <li><a href="/privacy">Privacy Policy</a></li>
                            <li><a href="/pages/work-trade">Work Trade</a></li>
                            <li><a href="/report-error">Report an Error</a></li>
                            <li><a href="/pages/feedback">Feedback & Harassment</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>


        <modal
            name="all-social-media"
            :adaptive="true"
            :scrollable="true"
            :width="'400px'"
            :max-width="400"
            :height="'auto'">
            <div class="is-padded">
                <button type="button" class="delete is-top-right has-margins-05" aria-label="close social media platforms window" @click="$modal.hide('all-social-media')"></button>
                <h4 class="title is-4">All our social media platforms</h4>

                <div class="field">
                    <div class="control">
                        <div>
                            <table style="width:100%;">
                                <tr @click.stop="$goTo('https://www.facebook.com/Xerocraft')">
                                    <td><i class="fab fa-2x fa-facebook"></i></td>
                                    <td>Xerocraft | Facebook</td>
                                </tr>
                                <tr @click.stop="$goTo('https://twitter.com/xerocraft')">
                                    <td><i class="fab fa-2x fa-twitter"></i></td>
                                    <td>Xerocraft | Twitter</td>
                                </tr>
                                <tr @click.stop="$goTo('https://www.youtube.com/user/xerocrafthacks')">
                                    <td><i class="fab fa-2x fa-youtube"></i></td>
                                    <td>Xerocraft | Youtube</td>
                                </tr>
                                <tr @click.stop="$goTo('https://www.instagram.com/xerocraft/')">
                                    <td><i class="fab fa-2x fa-instagram"></i></td>
                                    <td>Xerocraft | Instagram</td>
                                </tr>
                                <tr @click.stop="$goTo('https://www.flickr.com/photos/xerocraft/')">
                                    <td><i class="fab fa-2x fa-flickr"></i></td>
                                    <td>Xerocraft | Flickr</td>
                                </tr>
                                <tr><td colspan="2"><div class="is-divider"></div></td></tr>
                                <tr @click.stop="$goTo('https://www.instagram.com/xerocraft_wtf')">
                                    <td><i class="fab fa-2x fa-instagram"></i></td>
                                    <td>Xerocraft's WTF | Instagram</td>
                                </tr>
                                <tr @click.stop="$goTo('https://instagram.com/tucsonrepaircafe')">
                                    <td><i class="fab fa-2x fa-instagram"></i></td>
                                    <td>Tucson Repair Cafe | Instagram</td>
                                </tr>
                                <tr @click.stop="$goTo('https://www.youtube.com/@Xerocraft-Admin')">
                                    <td><i class="fab fa-2x fa-youtube"></i></td>
                                    <td>Xerocraft Webadmin Videos | Youtube</td>
                                </tr>
                            </table>

                        </div>
                    </div>
                </div>

            </div>
        </modal>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    props: {
        sponsorArray: {
            type: Array,
            required: false
        },
    },
}
</script>


<style scoped>
    .footer {
        position: relative;
        z-index: 1;
        padding-bottom: 0;
    }
    .sponsor {
        height:4rem;
        width:auto;
    }
    .sponsor img {
        max-width:8rem;
        max-height:100%;
    }
    .footer-map {
        margin: auto -1.5rem -0.5rem;
    }
    .footer-map iframe {
        width: 100%;
        height: 300px;
    }

    .menu-list li a {
        color: #b5b5b5;
    }
    .menu-list li a:hover {
        color: #000000;
    }

    .first-footer {
        padding: 2rem 1.5rem 0;
        position: relative;
        z-index: 1;
        background:white;

        border-top: 1px solid #dbdbdb;
        margin-top: 2rem;
    }

    td {
        line-height: 32px;
    }
    tr:hover {
        cursor: pointer;
        color: #f38135;
    }
    table td:nth-child(2) {
        padding-left:10px;
    }
</style>
