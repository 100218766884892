<template>

    <div ref="ribbons">
        <notifications group="Ribbons" position="bottom right" width="320" animation-type="velocity" :animation="animation" :closeOnClick="false" >

            <template slot="body" slot-scope="ribbon">
                <div class="ribbon-wrapper box shadow">
                    <div class="ribbon-blowhorn">
                        <img :src=" $store.state.assetRoute + '/images/blowhorn.svg' " alt="Blowhorn Image" width="100" aria-hidden="true">
                    </div>

                    <div class="ribbon-inner-wrapper">

                        <a v-if="canEdit" class="is-top-left m-2 has-text-white" :href="'/admin/ribbons/' + ribbon.id + '/edit'">
                            <span class="fa-stack">
                                <i class="fa fa-circle fa-stack-2x"></i>
                                <i class="fa fa-pen fa-stack-1x has-text-dark"></i>
                            </span>
                        </a>
                        <button class="delete is-top-right m-2" type="button" @click=" ribbon.close "></button>

                        <h4 class="title is-4">{{ ribbon.item.title }}</h4>

                        <div class="content-wrapper quill" style="text-align: left;">
                            <div class="ql-snow">
                                <div class="ql-editor py-0 px-0">
                                    <div v-html=" ribbon.item.text "></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </template>
        </notifications>
    </div>

</template>

<script>
    export default {
        name: 'Xero-Ribbons',
        props: {
            canEdit: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                animation: {
                    enter (element) {
                        let height = element.clientHeight
                        return {
                            height: [height, 0], // Animates from 0px to "height"
                            opacity: [1, 0] // Animates from 0 to 1
                        }
                    },
                    leave (element) {
                        setTimeout(() => {
                            this.$root.$refs['ribbon-bar'].checkRibbons()
                        }, 500);

                        return {
                            height: 0,
                            opacity: 0
                        }
                    }
                }
            };
        },
        mounted() {
            setTimeout(() => {
                this.checkRibbons();
            }, 1000);
        },
        methods: {
            checkRibbons() {
                if (!!this.$store.state.ribbons.length) {
                    this.pushNextRibbon();
                }
            },
            pushNextRibbon() {
                let ribbons = [...this.$store.state.ribbons];
                let ribbon = ribbons.shift();
                this.$store.commit('ribbons', ribbons);
                this.createRibbon(ribbon);
            },
            createRibbon(ribbon) {
                this.$notify({
                    group: 'Ribbons',
                    text: ribbon.description,
                    duration: ribbon.duration || -1,
                    title: ribbon.title,
                });
            }
        },
    }
</script>

<style lang="scss" scoped>

    .vue-notification-group {
        margin: 0 32px 64px 0;
        z-index: 1000 !important;
    }

    .xero-Ribbon {
        position: relative;
    }

    .vue-notification-wrapper {
        .ribbon-wrapper {
            margin: 100px 10px 20px;
            position: relative;
            padding-top: 50px;
            text-align: center;
            max-height: 70vh;
            display: flex;
            flex-direction: column;
        }
        .ribbon-inner-wrapper {
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }

        .ribbon-blowhorn {
            top: -65px;
            left: 0;
            position: absolute;
            display: block;
            width: 100%;
            text-align: center;
        }

        .content-wrapper {
            overflow-y: auto;
        }

    }

</style>
