/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./blank_import.scss');

import Vue from 'vue';
window.Pusher = require('pusher-js');

import Vuex from 'vuex'
import axios from 'axios';
import VTooltip from 'v-tooltip';
import VueAxios from 'vue-axios';
import VModal from 'vue-js-modal';
import VueMoment from 'vue-moment';
import {Swatches} from 'vue-color';
import moment from 'moment-timezone';
import VueEcho from 'vue-echo-laravel';
import waterfall from 'vue-waterfall2';
import VueTelInput from 'vue-tel-input';
import velocity from 'velocity-animate';
import JsonViewer from 'vue-json-viewer';
import Notifications from 'vue-notification';
import CKEditor from '@ckeditor/ckeditor5-vue';
import {HalfCircleSpinner} from 'epic-spinners';
import VueCarousel from '@chenfengyuan/vue-carousel';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';

import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

function lazyLoad(location) {
    return () => import(`./components/${location}.vue`);
}

Vue.component('xero-navigation', require('./components/General/Navigation.vue').default);
Vue.component('xero-footer', require('./components/General/Footer.vue').default);
Vue.component('xero-alerts', require('./components/General/Alerts.vue').default);
Vue.component('fancy-image', require('./components/General/FancyImage.vue').default);
Vue.component('xero-ribbons', require('./components/General/Ribbons.vue').default);
Vue.component('custom', require('./components/General/CustomComponent.vue').default);
Vue.component('fav-link', require('./components/Isolated/FavLink.vue').default);
Vue.component('open-or-closed', require('./components/Isolated/OpenOrClosed.vue').default);

Vue.component('full-page-slideshow', lazyLoad('Tools/FullPageSlideshow'));
Vue.component('pick-username', lazyLoad('Tools/PickUsername'));
Vue.component('pick-email', lazyLoad('Tools/PickEmail'));
Vue.component('pick-password', lazyLoad('Tools/PickPassword'));
Vue.component('album-linking', lazyLoad('Tools/AlbumLinking'));
Vue.component('add-poll', lazyLoad('Tools/AddPoll'));
Vue.component('attach-photo', lazyLoad('Tools/AttachPhoto'));
Vue.component('filter-medias', lazyLoad('Tools/FilterMedias'));
Vue.component('filter-users', lazyLoad('Tools/FilterUsers'));
Vue.component('filter-events', lazyLoad('Tools/FilterEvents'));
Vue.component('tagify', lazyLoad('Tools/Tagify'));
Vue.component('tag', lazyLoad('Tools/Tag'));
Vue.component('comments', lazyLoad('Tools/Comments'));
Vue.component('comment', lazyLoad('Tools/Comment'));
Vue.component('comment-form', lazyLoad('Tools/CommentForm'));
Vue.component('choose-users', lazyLoad('Tools/ChooseUsers'));
Vue.component('countdown', lazyLoad('Tools/Countdown'));
Vue.component('xero-color-picker', lazyLoad('Tools/ColorPicker'));
Vue.component('login-or-guest', lazyLoad('Tools/LoginOrGuest'));
Vue.component('input-editable', lazyLoad('Tools/InputEditable'));
Vue.component('textarea-editable', lazyLoad('Tools/TextareaEditable'));
Vue.component('boolean-editable', lazyLoad('Tools/BooleanEditable'));

Vue.component('xero-input', lazyLoad('Tools/Form-Basics/Input'));
Vue.component('xero-wysiwyg', lazyLoad('Tools/Form-Basics/WYSIWYG'));
Vue.component('xero-textarea', lazyLoad('Tools/Form-Basics/Textarea'));
Vue.component('xero-select', lazyLoad('Tools/Form-Basics/Select'));
Vue.component('xero-checkbox', lazyLoad('Tools/Form-Basics/Checkbox'));
Vue.component('xero-radio', lazyLoad('Tools/Form-Basics/Radio'));
Vue.component('xero-date', lazyLoad('Tools/Form-Basics/Date'));
Vue.component('xero-datetime', lazyLoad('Tools/Form-Basics/Datetime'));
Vue.component('confirm-link', lazyLoad('Tools/Form-Basics/ConfirmLink'));
Vue.component('confirm-button', lazyLoad('Tools/Form-Basics/ConfirmButton'));
Vue.component('confirm-form', lazyLoad('Tools/Form-Basics/ConfirmForm'));

Vue.component('animating-logo', lazyLoad('Isolated/AnimatingLogo'));
Vue.component('changing-words', lazyLoad('Isolated/ChangingWords'));
Vue.component('aside-generator', lazyLoad('Isolated/AsideGenerator'));
Vue.component('xero-carousel', lazyLoad('Isolated/Carousel'));
Vue.component('tiled-images', lazyLoad('Isolated/TiledImages'));
Vue.component('membership-packages', lazyLoad('Isolated/MembershipPackages'));
Vue.component('membership-package', lazyLoad('Isolated/MembershipPackage'));
Vue.component('contact-us', lazyLoad('Isolated/ContactUs'));
Vue.component('ticket', lazyLoad('Isolated/Ticket'));
Vue.component('dynamic-large-photo', lazyLoad('Isolated/DynamicLargePhoto'));
Vue.component('activity-card', lazyLoad('Isolated/ActivityCard'));
Vue.component('line-graph', lazyLoad('Isolated/LineGraph'));
Vue.component('doughnut-graph', lazyLoad('Isolated/DoughnutGraph'));
Vue.component('bar-graph', lazyLoad('Isolated/BarGraph'));
Vue.component('view-poll', lazyLoad('Isolated/ViewPoll'));
Vue.component('shop-week-hours', lazyLoad('Isolated/ShopWeekHours'));
Vue.component('pop-info', lazyLoad('Isolated/PopInfo'));
Vue.component('xero-user', lazyLoad('Isolated/XeroUser'));
Vue.component('oembed', lazyLoad('Isolated/XeroVideo'));
Vue.component('content-tile', lazyLoad('Isolated/ContentTile'));
Vue.component('content-tile-vertical', lazyLoad('Isolated/ContentTileVertical'));
Vue.component('event-tile', lazyLoad('Isolated/EventTile'));
Vue.component('file-download', lazyLoad('Isolated/FileDownload'));
Vue.component('all-tools', lazyLoad('Isolated/AllTools'));
Vue.component('notes', lazyLoad('Isolated/Notes'));
Vue.component('scroll-wrapper', lazyLoad('Isolated/ScrollWrapper'));
Vue.component('email-list-subscribe', lazyLoad('Isolated/EmailListSubscribe'));
Vue.component('tool-info', lazyLoad('Isolated/ToolInfo'));

Vue.component('donate', lazyLoad('Checkouts/Donate'));
Vue.component('ticket-checkout', lazyLoad('Checkouts/TicketCheckout'));
Vue.component('membership-checkout', lazyLoad('Checkouts/MembershipCheckout'));
Vue.component('discount-code', lazyLoad('Checkouts/DiscountCode'));
Vue.component('gift-card-store', lazyLoad('Checkouts/GiftCardStore'));
Vue.component('add-funds-for-user', lazyLoad('Checkouts/AddFundsForUser'));
Vue.component('payment-form', lazyLoad('Checkouts/PaymentForm'));
Vue.component('booked-space-checkout', lazyLoad('Checkouts/BookedSpaceCheckout'));
Vue.component('special-checkout', lazyLoad('Checkouts/SpecialCheckout'));

Vue.component('give-gift-card', lazyLoad('Page-Specific/GiveGiftCard'));
Vue.component('qr-code-redeeming', lazyLoad('Page-Specific/QrCodeRedeeming'));
Vue.component('media-image-control', lazyLoad('Page-Specific/MediaImageControl'));
Vue.component('control-media-tags', lazyLoad('Page-Specific/ControlMediaTags'));
Vue.component('media-tag-control', lazyLoad('Page-Specific/MediaTagControl'));
Vue.component('create-independent-tags', lazyLoad('Page-Specific/CreateIndependentTags'));
Vue.component('card-details', lazyLoad('Page-Specific/EditCardDetails'));
Vue.component('chat', lazyLoad('Page-Specific/Chat'));
Vue.component('event-index', lazyLoad('Page-Specific/EventIndex'));
Vue.component('checkin-page', lazyLoad('Page-Specific/CheckinPage'));
Vue.component('affiliate-link-generator', lazyLoad('Page-Specific/AffiliateLinkGenerator'));
Vue.component('affiliate-link-data', lazyLoad('Page-Specific/AffiliateLinkData'));
Vue.component('pick-instructor', lazyLoad('Page-Specific/PickInstructor'));
Vue.component('event-datetime-and-location', lazyLoad('Page-Specific/EventDatetimeAndLocation'));
Vue.component('event-location', lazyLoad('Page-Specific/EventLocation'));
Vue.component('event-repeat', lazyLoad('Page-Specific/EventRepeat'));
Vue.component('edit-publish', lazyLoad('Page-Specific/EditPublish'));
Vue.component('assign-ticket', lazyLoad('Page-Specific/AssignTicket'));
Vue.component('calendar-page', lazyLoad('Page-Specific/CalendarPage'));
Vue.component('membership-assign', lazyLoad('Page-Specific/MembershipAssign'));
Vue.component('membership-purchasable', lazyLoad('Page-Specific/MembershipPurchasable'));
Vue.component('shop-hour-control', lazyLoad('Page-Specific/ShopHourControl'));
Vue.component('role-users', lazyLoad('Page-Specific/RoleUsers'));
Vue.component('shop-users', lazyLoad('Page-Specific/ShopUsers'));
Vue.component('list-managers', lazyLoad('Page-Specific/ListManagers'));
Vue.component('list-item-control', lazyLoad('Page-Specific/ListItemControl'));
Vue.component('assign-work-trade-membership', lazyLoad('Page-Specific/AssignWorkTradeMembership'));
Vue.component('paying-members-table', lazyLoad('Page-Specific/PayingMembersTable'));
Vue.component('discount-codes-table', lazyLoad('Page-Specific/DiscountCodesTable'));
Vue.component('event-leads', lazyLoad('Page-Specific/EventLeads'));
Vue.component('membership-package-control', lazyLoad('Page-Specific/MembershipPackageControl'));
Vue.component('membership-category-control', lazyLoad('Page-Specific/MembershipCategoryControl'));
Vue.component('project-update-control', lazyLoad('Page-Specific/ProjectUpdateControl'));
Vue.component('project-update-control', lazyLoad('Page-Specific/ProjectUpdateControl'));
Vue.component('front-page-information-slides', lazyLoad('Page-Specific/FrontPageInformationSlides'));
Vue.component('rfid-edit', lazyLoad('Page-Specific/RfidEdit'));
Vue.component('rfid-add', lazyLoad('Page-Specific/RfidAdd'));
Vue.component('discount-code-creation', lazyLoad('Page-Specific/DiscountCodeCreation'));
Vue.component('shop-tasks-control', lazyLoad('Page-Specific/ShopTasksControl'));
Vue.component('shop-control', lazyLoad('Page-Specific/ShopControl'));
Vue.component('task-creation', lazyLoad('Page-Specific/TaskCreation'));
Vue.component('task-progress-creation', lazyLoad('Page-Specific/TaskProgressCreation'));
Vue.component('location-control', lazyLoad('Page-Specific/LocationControl'));
Vue.component('staffing-calendar', lazyLoad('Page-Specific/StaffingCalendar'));
Vue.component('manage-volunteers', lazyLoad('Page-Specific/ManageVolunteers'));
Vue.component('shop-tool-control', lazyLoad('Page-Specific/ShopToolControl'));
Vue.component('training-control', lazyLoad('Page-Specific/TrainingControl'));
Vue.component('tool-control', lazyLoad('Page-Specific/ToolControl'));
Vue.component('group-pages', lazyLoad('Page-Specific/GroupPages'));
Vue.component('sign-waiver', lazyLoad('Page-Specific/SignWaiver'));
Vue.component('trigger-builder', lazyLoad('Page-Specific/TriggerBuilder'));
Vue.component('trigger-viewer', lazyLoad('Page-Specific/TriggerViewer'));
Vue.component('manage-triggers', lazyLoad('Page-Specific/ManageTriggers'));
Vue.component('trigger-setup', lazyLoad('Page-Specific/TriggerSetup'));
Vue.component('trigger-simulation', lazyLoad('Page-Specific/TriggerSimulation'));
// Vue.component('event-wrap-up', lazyLoad('Page-Specific/EventWrapUp'));


Vue.component('swatches-picker', Swatches);
Vue.component('half-circle-spinner', HalfCircleSpinner);
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.component(VueCarousel.name, VueCarousel);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.mixin({
    computed: {
        $mode: {
            get: function () {
                return process.env.NODE_ENV === 'development' ? 'dev' : 'prod';
            }
        },
        $userLevel: {
            get: function () {
                if (this.$store.state.userLoggedIn) {
                    if (this.$store.state.user.admin === 1) return 3;
                    if (this.$store.state.userPayingMember === 1) return 2;
                    return 1;
                } else {
                    return 0;
                }
            }
        },
        $windowWidth: {
            get: function () {
                return window.innerHeight
            }
        }
    },
    mounted() {
        if(window.location.hash) {
            this.$baseHash = window.location.hash;
            if(this.$baseHash) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        let anchor = document.getElementById(this.$baseHash.replace('#', ''));
                        if(anchor) anchor.scrollIntoView();
                    }, 1000);
                });
            }
            window.history.replaceState({}, 'Xerocraft', window.location.protocol+'//'+window.location.host+window.location.pathname);
        }
    },
    methods: {
        $gen_mail_to_link: function(to, source) {
            return `<a href="mailto:${to}@${source}">${to}@${source}</a>`;
        },
        $debug: function (content, data, frozen = false) {
            if (this.$mode === 'dev') {
                if (data) {
                    if(frozen) data = JSON.parse(JSON.stringify(data));
                    console.log(content, data);
                }
                else console.log(content);
            }
        },
        $d: function (content, data = null, frozen = false) {
            this.$debug(content, data, frozen);
        },
        $sleep: function(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        $windowIs: function (comparer, descriptor) {
            descriptor = descriptor.toLowerCase();
            if (!['=', '==', '===', '<', '<=', '!=', '>', '>='].includes(comparer)) return false;
            if (!['mobile', 'tablet', 'desktop', 'widescreen', 'fullhd'].includes(descriptor)) return false;

            let upperLimit = 0;
            let lowerLimit = 0;
            if (descriptor === 'mobile') {
                lowerLimit = 0;
                upperLimit = 768;
            }
            if (descriptor === 'tablet') {
                lowerLimit = 769;
                upperLimit = 1023;
            }
            if (descriptor === 'desktop') {
                lowerLimit = 1024;
                upperLimit = 1215;
            }
            if (descriptor === 'widescreen') {
                lowerLimit = 1216;
                upperLimit = 1407;
            }
            if (descriptor === 'fullhd') {
                lowerLimit = 1408;
                upperLimit = 99999999;
            }

            let windowWidth = window.innerWidth;

            if (comparer === '=' || comparer === '==' || comparer === '===') return windowWidth > lowerLimit && windowWidth < upperLimit;
            else if (comparer === '<') return windowWidth < lowerLimit;
            else if (comparer === '<=') return windowWidth <= upperLimit;
            else if (comparer === '!=') return windowWidth < lowerLimit || windowWidth > upperLimit;
            else if (comparer === '>') return windowWidth > upperLimit;
            else if (comparer === '>=') return windowWidth >= lowerLimit;
            return false;
        },
        $getThumbUrl: function (source) {
            if (source.indexOf('_thumb') !== -1) return source;
            let split = source.split('.');
            split[split.length - 2] = split[split.length - 2] + '_thumb';
            return split.join('.');
        },
        $buildMediaUrl: function (media) {
            if (!this.$isEmptyObject(media)) {
                if (media.media_type === 'media') {
                    return '/storage/media_library/' + media.media.uuid;
                } else {
                    return '/storage/private_media/' + media.media.uuid;
                }
            }
            return '';
        },
        $capitalize: function(string) {
            let word = string.toString();
            return word.charAt(0).toUpperCase() + word.slice(1);
        },
        $copyToClipboard(text) {
            let tempInput = document.createElement("input");
            tempInput.value = text;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);

            this.$store.commit('success', 'COPIED!');
        },
        $getOrdinalNum(n) {
            return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
        },
        $outputDate(date, format) {
            if(date instanceof moment && date.isValid()) return date.format(format);
            if(typeof date.getTime !== 'function' || isNaN(date.getTime())) date = new Date(date);

            if(format === 'YYYY-MM-DD') return `${date.getFullYear()}-${this.$zeroPadded((date.getMonth() + 1))}-${this.$zeroPadded(date.getDate())}`;
            if(format === 'MM/DD/YYYY') return `${this.$zeroPadded((date.getMonth() + 1))}/${this.$zeroPadded(date.getDate())}/${date.getFullYear()}`;
            if(format === 'MM/DD/YY') return `${this.$zeroPadded((date.getMonth() + 1))}/${this.$zeroPadded(date.getDate())}/${date.getFullYear().toString().substr(-2)}`;
            if(format === 'M/D/YY') return `${(date.getMonth() + 1)}/${date.getDate()}/${date.getFullYear().toString().substr(-2)}`;

            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            if(format === 'MMM Do') return `${monthNames[date.getMonth()]} ${this.$getOrdinalNum(date.getDate())}`;
            if(format === 'MMM Do, YYYY') return `${monthNames[date.getMonth()]} ${this.$getOrdinalNum(date.getDate())}, ${date.getFullYear()}`;
            if(format === 'MMM Do, YYYY @ h:mma') {
                let hours = date.getHours();
                hours = hours % 12;
                hours = hours ? hours : 12;
                return `${monthNames[date.getMonth()]} ${this.$getOrdinalNum(date.getDate())}, ${date.getFullYear()} @ ${hours}:${this.$zeroPadded(date.getMinutes())}${date.getHours() >= 12 ? 'pm' : 'am'}`;
            }
            if(format === 'h:mma') {
                let hours = date.getHours();
                hours = hours % 12;
                hours = hours ? hours : 12;
                return `${hours}:${this.$zeroPadded(date.getMinutes())}${date.getHours() >= 12 ? 'pm' : 'am'}`;
            }
            if(format === 'YYYY-MM-DD HH:mm:ss') return `${date.getFullYear()}-${this.$zeroPadded((date.getMonth() + 1))}-${this.$zeroPadded(date.getDate())} ${this.$zeroPadded(date.getHours())}:${this.$zeroPadded(date.getMinutes())}:${this.$zeroPadded(date.getSeconds())}`;
            if(format === 'YYYY-MM-DDTHH:mm:ss') return `${date.getFullYear()}-${this.$zeroPadded((date.getMonth() + 1))}-${this.$zeroPadded(date.getDate())}T${this.$zeroPadded(date.getHours())}:${this.$zeroPadded(date.getMinutes())}:${this.$zeroPadded(date.getSeconds())}`;
            if(format === 'HH:mm:ss') return `${this.$zeroPadded(date.getHours())}:${this.$zeroPadded(date.getMinutes())}:${this.$zeroPadded(date.getSeconds())}`;

            return format;
        },
        $isIterable: function (obj) {
            // checks for null and undefined
            if (obj == null) {
                return false;
            }
            return typeof obj !== 'string' && (typeof obj[Symbol.iterator] === 'function' || !(Object.keys(obj) instanceof Error && (typeof obj === 'object')));
        },
        $isLight(color) {
            return this.$lightOrDark(color) === 'light';
        },
        $isDark(color) {
            return this.$lightOrDark(color) === 'dark';
        },
        $isEmptyObject(object) {
            return !object || !Object.keys(object).length;
        },
        $generateRandomColor() {
            return '#' + ('ABCDEF0123456789'.split('').sort(function(){return 0.5-Math.random()}).join('').substr(0, 6));
        },
        $slugify(string) {
            const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'; //replace these
            const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'; //with these
            const p = new RegExp(a.split('').join('|'), 'g');

            return string.toString().toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with -
                .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                .replace(/&/g, '-and-') // Replace & with 'and'
                .replace(/[^\w\-]+/g, '') // Remove all non-word characters
                .replace(/\-\-+/g, '-') // Replace multiple - with single -
                .replace(/^-+/, '') // Trim - from start of text
                .replace(/-+$/, ''); // Trim - from end of text
        },
        $strippedString(string) {
            let doc = new DOMParser().parseFromString(string, 'text/html');
            return doc.body.textContent || '';
        },
        $lightOrDark(color) {

            // Check the format of the color, HEX or RGB?
            let r, g, b;
            if (color.match(/^rgb/)) {

                // If HEX --> store the red, green, blue values in separate variables
                color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

                r = color[1];
                g = color[2];
                b = color[3];
            } else {

                // If RGB --> Convert it to HEX: http://gist.github.com/983661
                color = +("0x" + color.slice(1).replace(
                        color.length < 5 && /./g, '$&$&'
                    )
                );

                r = color >> 16;
                g = color >> 8 & 255;
                b = color & 255;
            }

            // HSP equation from http://alienryderflex.com/hsp.html
            let hsp = Math.sqrt(
                0.299 * (r * r) +
                0.587 * (g * g) +
                0.114 * (b * b)
            );

            // Using the HSP value, determine whether the color is light or dark
            if (hsp > 127.5) {

                return 'light';
            } else {

                return 'dark';
            }
        },
        $toArray(e) {
            let data = [];
            let i = 0;
            Object.keys(e).forEach(key => {
                data[i] = e[key];
                i++;
            });
            return data;
        },
        $displayError(error) {
            this.$d(error);
            this.$d(error.response);
            if (error.response && error.response.data && error.response.data.errors) {
                this.$store.commit('errors', this.$toArray(error.response.data.errors));
            } else if (error.response && error.response.data && error.response.data.message) {
                this.$store.commit('error', error.response.data.message);
            } else {
                this.$store.commit('error', error.message);
            }
        },
        $prepareRoute(route, replacements) {
            let reps = Object.entries(replacements);
            for (let [needle, replacement] of reps) {
                route = route.replace(needle, replacement);
            }
            return route;
        },
        $isNumeric(s) {
            return !isNaN(s - parseFloat(s));
        },
        $momentUTC(date) {
            return this.$moment(date + ' +0000', 'YYYY-MM-DD HH:mm:ss ZZ');
        },
        $zeroPadded(num) {
            return num < 10 ? `0${num}` : num;
        },
        $goTo(url) {
            window.location.href = url;
        },
        $currentUrl() {
            return this.$store.state.url + window.location.pathname;
        },
        $addToObject(obj, key, value, index) {
            // Create a temp object and index variable
            let temp = {};
            let i = 0;

            // Loop through the original object
            for (let prop in obj) {
                if (obj.hasOwnProperty(prop)) {

                    // If the indexes match, add the new item
                    if (i === index && key && value) {
                        temp[key] = value;
                    }

                    // Add the current item in the loop to the temp obj
                    temp[prop] = obj[prop];

                    // Increase the count
                    i++;

                }
            }

            // If no index, add to the end
            if (!index && key && value) {
                temp[key] = value;
            }

            return temp;

        },
        $avatar_url: function(avatar, name) {
            if (!!avatar) return avatar.includes("http") ? avatar : (this.$store.state.url + avatar);
            let color = this.$store.state.user && this.$store.state.user.color ? this.$store.state.user.color : 'a2a6b2';
            return 'https://ui-avatars.com/api/?name=' + name.replace(/ /g, '+').replace(/-/g, '+').replace(/_/g, '+') + '&size=120&bold=true&background=' + color;
        },
        $addPopEvent: function(event) {
            let history = [...this.$store.state.popHistory];
            history.push(event);
            this.$store.commit('popHistory', history);
            window.location.hash = history.join('+');
        },
        $removePopEvent: function(event = null) {
            let history = [...this.$store.state.popHistory];
            if(!event) history.pop();
            else if(history.length > 0) {
                history.splice(history.indexOf(event), 1);
            }
            this.$store.commit('popHistory', history);
            window.location.replace(window.location.protocol+'//'+window.location.host+window.location.pathname+'#'+history.join('+'));
        },
        $latestPopEvent: function() {
            let latest = [...this.$store.state.popHistory].slice(-1);
            let hash = [...window.location.hash];
            if(hash[0] === '#') hash.shift();
            hash = hash.join('').split('+');
            if(latest.length > 0 && hash.slice(-1)[0] !== latest[0]) return latest[0];
            return '';
        },
        $back: function() {
            window.history.back();
        },
        $containsThis: function(array, data) {
            if(isObject(array)) array = Object.values(array);
            let searcher = function(array, data) {
                for (let i = 0; i < array.length; i++) {
                    if(Array.isArray(array[i]) && searcher(array[i], data)) return true;
                    if(isObject(array[i]) && searcher(Object.values(array[i]), data)) return true;
                    if(array[i] === data) return true;
                }
                return false;
            };
            return searcher(array, data);
        },
    }
});

Vue.use(VueAxios, axios);
Vue.use(VueMoment, {moment});
Vue.use(Vuex);
Vue.use(JsonViewer);
Vue.use(VModal, {dialog: true, dynamic: true, dynamicDefaults: {clickToClose: true}, injectModalsContainer: true});
Vue.use(Notifications, { velocity } );
Vue.use(CKEditor);
Vue.use(VueTelInput, []);
Vue.use(VTooltip);
Vue.use(waterfall);
Vue.use(VueEcho, {
    broadcaster: 'pusher',
    key: window.Laravel ? window.Laravel.pusherAppKey : '',
    cluster: window.Laravel ? window.Laravel.pusherAppCluster : '',
    encrypted: true,
});

const defaultStates = {
    assetRoute: window.Laravel ? window.Laravel.assetRoute : '',
    url: window.Laravel ? window.Laravel.url : '',
    csrfToken: window.Laravel ? window.Laravel.csrfToken : '',
    user: window.Laravel ? window.Laravel.user : {},
    userLoggedIn: window.Laravel ? window.Laravel.userLoggedIn : false,
    userPayingMember: window.Laravel ? window.Laravel.userPayingMember : 0,
    userImpersonating: window.Laravel ? window.Laravel.userImpersonating : false,
    userFavLinks: window.Laravel ? window.Laravel.userFavLinks : [],
    old: window.Laravel ? window.Laravel.old : [],
    errors: window.Laravel ? window.Laravel.errors : [],
    success: window.Laravel ? window.Laravel.success : null,
    statuses: window.Laravel ? window.Laravel.statuses : null,
    dialogs: window.Laravel ? window.Laravel.dialogs : null,
    error: window.Laravel ? window.Laravel.error : null,
    warning: window.Laravel ? window.Laravel.warning : null,
    notification: window.Laravel ? window.Laravel.notification : null,
    ribbons: window.Laravel ? window.Laravel.ribbons : null,
    preferredEmailOptions: window.Laravel ? window.Laravel.preferredEmailOptions : false,
    popHistory: [],
    filteredMedias: null,
};

const store = new Vuex.Store({
    state: defaultStates,
    strict: true,
    mutations: {
        user: (state, data) => state.user = data,
        userLoggedIn: (state, data) => state.userLoggedIn = data,
        userPayingMember: (state, data) => state.userPayingMember = data,
        filteredMedia: (state, medias) => state.filteredMedias = medias,
        old: (state, data) => state.old = data,
        errors: (state, data) => state.errors = data,
        success: (state, data) => state.success = data,
        statuses: (state, data) => state.statuses = data,
        dialogs: (state, data) => state.dialogs = data,
        error: (state, data) => state.error = data,
        warning: (state, data) => state.warning = data,
        notification: (state, data) => state.notification = data,
        ribbons: (state, data) => state.ribbons = data,
        popHistory: (state, data) => state.popHistory = data,
    },
});

const app = new Vue({
    el: '#app',
    store,
    data() {
        return {
            $baseHash: '',
            container: {},
            $container: {}
        };
    },
});
window.VueApp = app;

function isObject(i) {
    return typeof i === 'object' && i instanceof Object && !(i instanceof Array);
}

// check if an element exists in array using a comparer function
// comparer : function(currentElement)
Array.prototype.inArray = function (comparer) {
    for (var i = 0; i < this.length; i++) {
        if (comparer(this[i])) return true;
    }
    return false;
};

// adds an element to the array if it does not already exist using a comparer
// function
Array.prototype.pushIfNotExist = function (element, filterIfTrueMethod) {
    if (!this.inArray(filterIfTrueMethod)) {
        this.push(element);
    }
};
