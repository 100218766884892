<script>
    export default {
        name: 'Custom-Component',
        data() {
            return {
                data: {}
            };
        },
        mounted() {this.$emit('mounted');},
        created() {this.$emit('created');},
        template: '<div><slot></slot></div>'
    }
</script>
