<template>

    <div>
        <notifications group="shopOpen" width="300" position="top right" style="z-index: 200;top: 52px !important;margin-right: 0 !important;">
            <template slot="body">
                <div class="now-open-notification">
                    <svg viewbox="0 0 60 40" width="60" height="40">
                        <path d="M60,0 L60,40 C30,40 30,0 0,0z" fill="#363636" />
                    </svg>
                    <a v-if="currentHours === 'Open Hack'" :href="routeHoursPage">
                        <i>Free access for all - Come in!</i>
                        <span>We're <b class="glowing-green-text">OPEN</b> for Open Hack</span>
                    </a>
                    <a v-if="currentHours === 'Rainbow Open Hack'" :href=" $store.state.url + '/wtf' ">
                        <i>Exclusive access for Women & LGBTQIA+ folk!</i>
                        <span>We're <b class="glowing-purple-text">OPEN</b> for Rainbow Open Hack</span>
                    </a>
                    <a v-if="currentHours === 'Rainbow Hack Upcoming'" :href=" $store.state.url + '/wtf' ">
                        <i>Rainbow Open Hack tonight, 6pm to 10pm!</i>
                        <span><b class="glowing-purple-text">RAINBOW</b> hack is coming up tonight!</span>
                    </a>
                </div>
            </template>
        </notifications>
    </div>

</template>

<script>
    export default {
        name: 'Open-Or-Closed',
        props: {
            routeHoursPage: {
                type: String,
                required: true
            },
            currentHours: {
                type: String,
                required: true
            }
        },
        mounted() {
            setTimeout(() => {
                if(! ['Rainbow Hack Upcoming', 'Open Hack', 'Rainbow Open Hack'].includes(this.currentHours)) return;
                this.$notify({
                    group: 'shopOpen',
                    type: 'open-notification',
                    duration: -1,
                });
            }, 2000);
        }
    }
</script>

<style lang="scss" scoped>

    .vue-notification-group {
        z-index: 30 !important;
    }

    .now-open-notification {
        & > a {
            display: block;
            position: absolute;
            left: 60px;
            top: 0;
            color: whitesmoke;
            background: #363636;
            height: 40px;
            width: calc(100% - 60px);
        }

        & span {
            font-size: 12px;
            font-weight: bold;
            position: absolute;
            bottom: 5px;
            left: 0;
        }

        & > a > i {
            font-size: 10px;
            position: absolute;
            top: 0;
            left: 0;
        }

        & svg {
            position: absolute;
            top: 0;
            left: 0;
        }
    }


    .glowing-green-text {
        animation: glowgreen 1s ease-in-out infinite alternate;
    }


    @keyframes glowgreen {
        from {
            text-shadow: 0 0 2px rgba(84, 87, 84, 0.89),
            0 0 5px #a5ffb4,
            0 0 9px #11ff15,
            0 0 11px #11ff15,
            0 0 12px #11ff15,
            0 0 13px #11ff15,
            0 0 14px #11ff15;
            color: #fff;
        }
        to {
            text-shadow: 0 0 2px rgba(84, 87, 84, 0.89),
            0 0 5px #a5ffb4,
            0 0 9px #52ff70,
            0 0 11px #52ff70,
            0 0 12px #11ff15,
            0 0 13px #11ff15,
            0 0 14px #52ff70;
            color: #b3ffc0;
        }
    }


    .glowing-purple-text {
        animation: glowpurple 1s ease-in-out infinite alternate;
    }


    @keyframes glowpurple {
        from {
            text-shadow: 0 0 2px rgba(84, 87, 84, 0.89),
            0 0 5px #ffcae2,
            0 0 9px #ff2483,
            0 0 11px #ff2483,
            0 0 12px #ff2483,
            0 0 13px #ff2483,
            0 0 14px #ff2483;
            color: #fff;
        }
        to {
            text-shadow: 0 0 2px rgba(84, 87, 84, 0.89),
            0 0 5px #ffcae2,
            0 0 9px #ff4c9b,
            0 0 11px #ff4c9b,
            0 0 12px #ff2483,
            0 0 13px #ff2483,
            0 0 14px #ff4c9b;
            color: #ffbad9;
        }
    }
</style>
