<template>

    <figure class="image">

        <a @click.prevent="openImageModal">
            <img :src="path" :alt="alt">
        </a>

        <figcaption v-if="caption">{{ caption }}</figcaption>

        <modal
            :name="'image-view-modal_' + _uid"
            :adaptive="true"
            :width="'80%'"
            :height="'auto'"
            :max-height=" modalHeight "
            :max-width=" modalWidth "
            :classes="'lightbox-overlay-modal'">
            <div>
                <img :src="path" :alt="alt">
                <button type="button" class="image-view-modal-delete delete" aria-label="close album selection" @click="closeImageModal"></button>
            </div>
        </modal>
    </figure>


</template>


<script>
    export default {
        name: 'Fancy-Image',
        props: {
            path: {
                type: String,
                required: true,
            },
            thumb: {
                type: String,
                required: false,
            },
            alt: {
                type: String,
                required: false,
                default: '',
            },
            caption: {
                type: String,
                required: false,
                default: '',
            }
        },
        data() {
            return {
                modalHeight: 200,
                modalWidth: 200
            };
        },
        methods: {
            openImageModal: function () {
                let img = new Image();
                img.src = this.path;

                let boxWidth = window.innerWidth * 0.8;
                let boxHeight = window.innerHeight * 0.8;

                if(img.height < boxHeight && img.width < boxWidth) {
                    this.modalHeight = img.height;
                    this.modalWidth = img.width;
                } else {
                    let ratio = img.height / img.width;

                    if ((img.height - boxHeight) > (img.width - boxWidth)) {
                        //The height of the image controls
                        this.modalHeight = boxHeight;
                        this.modalWidth = boxHeight / ratio;

                    } else {
                        //The width of the image controls
                        this.modalWidth = boxWidth;
                        this.modalHeight = boxWidth * ratio;
                    }
                }
                this.$modal.show('image-view-modal_' + this._uid);
            },
            closeImageModal: function () {
                this.$modal.hide('image-view-modal_' + this._uid);
            },
        }
    }
</script>


<style scoped lang="scss">
    .lightbox-overlay-modal {
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-content: center;

        & > div {
            position: relative;
            align-self: center;
            height: 100%;

            & img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .image-view-modal-delete {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }
</style>
