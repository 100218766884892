<template>
    <div :class="'navbar is-fixed-top is-dark is-transparent' + (forceShrink ? ' force-shrink-nav' : '')">
        <div ref="navbarLeft" class="navbar-brand">
            <a title="Return to the home page" class="navbar-item" :href="home_url">
                <img width="28" height="28" :src=" $store.state.assetRoute + '/images/SmallLogo-light.png' " alt="Home Button Logo"/>
                <span :class=" (forceShrink ? '' : 'is-hidden-desktop ') + 'is-size-4 is-size-6-mobile is-italic'" style="color: #D4D4D4;">EROCRAFT</span>
            </a>

            <a title="Visit our Facebook page" class="navbar-item is-hidden-mobile is-primary-on-hover px-1" href="https://www.facebook.com/Xerocraft" style="background-color: transparent;"><i class="fab fa-facebook-f"></i></a>
            <a title="Visit our Twitter page" class="navbar-item is-hidden-mobile is-primary-on-hover px-1" href="https://twitter.com/xerocraft" style="background-color: transparent;"><i class="fab fa-twitter"></i></a>
            <a title="Play our Radio station: KMKR 99.9 FM" @click.prevent=" popOutPlayer " href="http://player.live365.com/a25079?l" target="_blank" :class="'navbar-item is-primary-on-hover px-1 has-text-centered ' + (forceShrink ? '' : 'is-hidden-desktop')" style="background-color: transparent;">
                <i class="fas fa-play-circle"></i>
            </a>

            <div :class="'mobile-menus-wrapper ' + (forceShrink ? '' : 'is-hidden-desktop') ">

                <!-- MOBILE & TABLET MENU -->
                <div v-if=" user_logged_in " class="navbar-item has-text-light" :class="[xHasUnreadNotifications ? 'has-unread' : '']" >
                    <div class="is-flex has-children-centered cursor-pointer" @click=" toggleNotification "><i class="fas fa-bell"></i></div>

                    <div class="notification-side-wrapper" :class=" notificationsOpen ? 'notifications-open' : '' ">
                        <div class="is-100-width has-background-grey notification-header">
                            <div class="is-top-right cursor-pointer" @click=" toggleNotification ">
                                <button class="delete" type="button"></button>
                            </div>

                            <span><i class="fas fa-bell"></i> &nbsp; Notifications</span>
                        </div>

                        <div class="notifications-inner-wrapper">
                            <div class="notifications-loading" v-if=" loadingNotifications ">
                                <div>
                                    <p>
                                        <img :src=" $store.state.assetRoute + '/images/loading.gif' " alt="Loading Icon">
                                    </p>
                                </div>
                            </div>

                            <div v-else class="has-text-dark notifications-content is-padded is-100-height">

                                <div v-if=" xNotifications.length === 0 " class="box">You have no notifications!</div>

                                <div v-for=" notification in xNotifications " class="box" :class=" !notification.read_at ? 'is-unread has-background-primary-light' : '' ">

                                    <p class="is-capitalized has-text-weight-bold">{{ notification.data.type.replace('-', ' ') }}</p>

                                    <div class="is-size-7 has-margins-05 has-margins-tb">
                                        <div class="content" v-html=" notification.data.body "></div>
                                    </div>
                                    <p class="is-size-7 has-text-grey">
                                        {{ $moment(notification.created_at).fromNow() }}
                                    </p>

                                </div>

                                <div v-if=" remainingUnread > 0 " class="box is-unread has-background-primary-light">
                                    <a :href=" url.notificationsIndex ">You have {{ remainingUnread }} additional unread {{ remainingUnread > 1 ? 'notification' : 'notifications' }}</a>
                                </div>

                                <div class="has-text-centered has-text-weight-bold">
                                    <a :href=" url.notificationsIndex ">View All Notifications</a>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <a class="navbar-item has-text-light" :class="[xHasUnreadMessages ? 'has-unread' : '']" :href="url.messagesIndex"><i class="fas fa-comment-dots"></i></a>

                <a role="button" class="navbar-burger user-menu-button navbar-item"
                   @click=" toggleUserMenu " :class="[isUserMenuActive ? 'is-active' : '']"
                   aria-label="profile options menu" aria-expanded="false" data-target="userDropdownMenu">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <div class="avatar">
                        <div :style="'background-image: url(' + $avatar_url(user.avatar, user.name || user.username) + ');'"></div>
                    </div>

                </a>
                <a role="button" class="navbar-burger" :class="[isMainMenuActive ? 'is-active' : '']" @click=" toggleMainMenu " aria-label="Menu" aria-expanded="false" data-target="navbarDropdownMenu">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>

            </div>
        </div>


        <div class="menu-open-overlay" v-if=" isMainMenuActive " @click=" toggleMainMenu "></div>
        <div class="menu-open-overlay" v-if=" isUserMenuActive " @click=" toggleUserMenu "></div>

        <div ref="navbarRight" class="navbar-menu" v-bind:class="[isMainMenuActive ? 'is-active' : '']" id="navbarDropdownMenu">

            <!-- START MENU GENERATION FROM JSON -->
            <div class="navbar-start">
                <template v-for="(branch, key) in menuJson">
                    <template v-if="branch.type === 'LINK'">
                        <a v-if=" branch.title.toUpperCase() !== '[[ON-AIR]]' " class="navbar-item is-size-7-desktop is-size-6-widescreen" :href="branch.URL" :target="branch.opens">{{ branch.title }}</a>
                        <a v-else title="Play our Radio station: KMKR 99.9 FM" @click.prevent=" popOutPlayer " :href=" branch.URL " target="_blank" :class="'navbar-item is-hidden-mobile is-primary-on-hover px-1 has-text-centered ' + (forceShrink ? 'is-hidden' : 'is-hidden-touch')" style="background-color: transparent;">
                            <span class="glowing-on-air mx-1 has-text-weight-bold is-size-7-desktop is-size-6-widescreen">ON AIR</span>
                            <i class="fas fa-play-circle"></i>
                        </a>
                    </template>
                    <div v-else class="navbar-item has-dropdown is-hoverable is-size-7-desktop is-size-6-widescreen" :key="branch.id">

                        <a class="navbar-link is-arrowless"
                           @click="toggleCollapse(branch)">
                            {{ branch.title }}
                            <span class="navbar-arrow"
                                  :class="(expanded.includes(branch) && ($windowIs('<=', 'tablet') || forceShrink)) || previewMode ? 'is-open' : ''">
                                <i class="fas fa-chevron-down"></i>
                            </span>
                        </a>

                        <div class="navbar-dropdown is-boxed"
                             v-show="(expanded.includes(branch) || ($windowIs('>', 'tablet') && !forceShrink)) || previewMode ">
                            <a class="navbar-item"
                               v-for="link in branch.links"
                               :href="link.URL"
                               :target="link.opens">{{
                                    link.title }}</a>
                        </div>
                    </div>
                </template>
            </div>
            <!-- END MENU GENERATION -->

            <div class="navbar-end">
                <template v-if=" ! user_logged_in">

                    <div :class=" 'navbar-item ' + (forceShrink ? 'is-hidden' : 'is-hidden-touch')">

                        <a class="navbar-item button is-small is-dark" :href="url.login" aria-label="login button"><span class="icon is-small" aria-hidden="true"><i class="fas fa-sign-in-alt"></i></span><span>Login</span></a>
                        <a class="navbar-item button is-primary is-small" :href="url.register" aria-label="Register button">Sign Up</a>

                    </div>

                </template>
                <template v-else>

                    <!-- DESKTOP MENU -->

                    <div v-if=" user_logged_in " class="navbar-item has-text-light" :class="[xHasUnreadNotifications ? 'has-unread' : '']" >
                        <div :class="'is-flex has-children-centered cursor-pointer ' + (forceShrink ? 'is-hidden' : 'is-hidden-touch') " @click=" toggleNotification "><i class="fas fa-bell"></i></div>

                        <div class="notification-modal-wrapper box" v-if=" notificationsOpen ">

                            <div class="notifications-inner-wrapper">
                                <div class="notifications-loading" v-if=" loadingNotifications ">
                                    <div>
                                        <p>
                                            <img :src=" $store.state.assetRoute + '/images/loading.gif' " alt="Loading Icon">
                                        </p>
                                    </div>
                                </div>

                                <div v-else class="has-text-dark notifications-content is-padded is-100-height">

                                    <div v-if=" xNotifications.length === 0 " class="box">You have no notifications!</div>

                                    <div v-for=" notification in xNotifications " class="box" :class=" !notification.read_at ? 'is-unread has-background-primary-light' : '' ">

                                        <p class="is-capitalized has-text-weight-bold">{{ notification.data.type.replace('-', ' ') }}</p>

                                        <div class="is-size-7 has-margins-05 has-margins-tb">
                                            <div class="content" v-html=" notification.data.body "></div>
                                        </div>
                                        <p class="is-size-7 has-text-grey">
                                            {{ $moment(notification.created_at).fromNow() }}
                                        </p>

                                    </div>

                                    <div v-if=" remainingUnread > 0 " class="box is-unread has-background-primary-light">
                                        <a :href=" url.notificationsIndex ">You have {{ remainingUnread }} additional unread {{ remainingUnread > 1 ? 'notification' : 'notifications' }}</a>
                                    </div>

                                    <div class="has-text-centered has-text-weight-bold">
                                        <a :href=" url.notificationsIndex ">View All Notifications</a>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="menu-open-overlay" v-if=" notificationsOpen " @click=" toggleNotification "></div>
                    </div>

                    <a :class="'navbar-item ' + (forceShrink ? 'is-hidden' : 'is-hidden-touch') + (xHasUnreadMessages ? ' has-unread' : '')" :href="url.messagesIndex"><i class="fas fa-comment-dots"></i></a>

                    <div :class="'navbar-item has-dropdown is-hoverable is-paddingless ' + (forceShrink ? 'is-hidden' : 'is-hidden-touch')">
                        <a role="button" class="navbar-link navbar-burger user-menu-button is-arrowless"
                           aria-label="profile options menu" aria-expanded="false" data-target="userDropdownMenu">
                            <div class="avatar">
                                <div :style="'background-image: url(' + $avatar_url(user.avatar, user.name || user.username) + ');'"></div>
                            </div>
                        </a>
                        <div class="navbar-dropdown is-boxed user-dropdown">
                            <a :href="url.usersSettings" class="navbar-item">Account</a>
                            <a :href="url.usersProfilesShow" class="navbar-item">Profile</a>
                            <a :href="url.accountOrdersAll" class="navbar-item">Orders</a>
                            <a :href="url.accountMembershipIndex" class="navbar-item">Membership</a>
                            <a :href="url.accountTicketsAll" class="navbar-item">Tickets / Admissions</a>
                            <a :href="url.accountCertifications" class="navbar-item">Certifications</a>
                            <a :href="url.accountBookings" class="navbar-item">Booked Spaces</a>
                            <a :href="url.dashboard" class="navbar-item">Your Dashboard</a>
                            <a v-if="!!$store.state.user.admin" :href="url.adminDashboard" class="navbar-item">Admin Dashboard</a>
                            <a v-for=" (link, index) in $store.state.userFavLinks " :key="'user-menu-'+index" :is="'fav-link'" type="right" class="navbar-item" :href=" link.url ">{{ link.title }}</a>
                            <hr class="navbar-divider">

                            <a v-if=" ! $store.state.userImpersonating " class="navbar-item button is-light" @click.prevent=" $refs.logoutForm.submit() ">Logout</a>
                            <a v-if=" $store.state.userImpersonating " class="navbar-item button is-danger is-small" @click.prevent=" $refs.stopImpersonatingForm.submit() ">STOP IMPERSONATING</a>
                        </div>
                    </div>

                </template>
            </div>
        </div>

        <div class="navbar-menu" :class="[isUserMenuActive ? 'is-active' : '']" id="userDropdownMenu">
            <div class="navbar-start">

            </div>
            <div class="navbar-end">
                <div v-if=" ! user_logged_in" role="button" class="navbar-item has-dropdown">
                    <div class="navbar-dropdown is-boxed">
                        <div class="navbar-item">
                            <a class="navbar-item button is-primary" :href="url.register">Sign Up</a>
                            <hr class="navbar-divider">
                            <a class="navbar-item button is-light" :href="url.login">Login</a>
                        </div>
                    </div>
                </div>
                <div v-else role="button" class="navbar-item has-dropdown">
                    <div class="navbar-dropdown is-boxed">
                        <a :href="url.usersSettings" class="navbar-item">Account</a>
                        <a :href="url.usersProfilesShow" class="navbar-item">Profile</a>
                        <a :href="url.accountOrdersAll" class="navbar-item">Orders</a>
                        <a :href="url.accountMembershipIndex" class="navbar-item">Membership</a>
                        <a :href="url.accountTicketsAll" class="navbar-item">Tickets / Admissions</a>
                        <a :href="url.accountCertifications" class="navbar-item">Certifications</a>
                        <a :href="url.accountBookings" class="navbar-item">Booked Spaces</a>
                        <a :href="url.dashboard" class="navbar-item">Your Dashboard</a>
                        <a v-if="!!$store.state.user.admin" :href="url.adminDashboard" class="navbar-item">Admin Dashboard</a>
                        <a v-for=" (link, index) in $store.state.userFavLinks " :key="'user-menu-mobile-'+index" :is="'fav-link'" type="right" class="navbar-item" :href=" link.url ">{{ link.title }}</a>
                        <hr class="navbar-divider">

                        <a v-if=" ! $store.state.userImpersonating " class="navbar-item button is-light" @click.prevent=" $refs.logoutForm.submit() ">Logout</a>
                        <a v-if=" $store.state.userImpersonating " class="navbar-item button is-danger is-small" @click.prevent=" $refs.stopImpersonatingForm.submit() ">STOP IMPERSONATING</a>
                    </div>
                </div>
            </div>
        </div>

        <!--hidden logout form, necessary to POST-request the logout -->
        <form class="is-hidden" id="logout-form" :action="url.logout" method="POST" ref="logoutForm">
            <input type="hidden" name="_token" :value="csrf_token"/>
        </form>

        <!--hidden stop-impersonating form, necessary to POST-request -->
        <form v-if=" $store.state.userImpersonating " class="is-hidden" ref="stopImpersonatingForm" :action="url.stopImpersonating" method="POST">
            <input type="hidden" name="_token" :value="csrf_token"/>
            <input type="hidden" name="_method" value="DELETE">
        </form>

    </div>

</template>


<script>
export default {
    name: 'Xero-Navigation',
    props: {
        menuJson: {
            type: Array,
            required: true,
        },
        url: {
            type: Object,
            required: true,
        },
        hasUnreadMessages: {
            type: Boolean,
            required: true,
        },
        hasUnreadNotifications: {
            type: Boolean,
            required: true,
        },
        previewMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            isMainMenuActive: !!this.previewMode,
            isUserMenuActive: false,
            user: this.$store.state.user,
            user_logged_in: this.$store.state.userLoggedIn,
            home_url: this.$store.state.url,
            csrf_token: this.$store.state.csrfToken,
            expanded: [],
            window: {
                width: 0,
            },
            playingWindow: null,

            loadingNotifications: false,
            xHasUnreadMessages: this.hasUnreadMessages,
            xHasUnreadNotifications: this.hasUnreadNotifications,
            notificationsOpen: false,
            xNotifications: [],
            remainingUnread: 0,
            forceShrink: false,
            waitingOnResize: false,
            checking: {}
        }
    },
    watch: {
        notificationsOpen: function(upcoming, previous) {
            if(! upcoming) {
                this.closeNotifications();
            } else {
                this.openNotifications();
            }
        },
        isMainMenuActive: function(upcoming, previous) {
            if(! upcoming) {
                this.closeMainMenu();
            } else {
                this.openMainMenu();
            }
        },
        isUserMenuActive: function(upcoming, previous) {
            if(! upcoming) {
                this.closeUserMenu();
            } else {
                this.openUserMenu();
            }
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        let self = this;
        window.addEventListener('popstate', function (event) {
            let latest = self.$latestPopEvent();
            if ( latest === 'notifications-open' ) {
                self.notificationsOpen = false;
            }
            else if( latest === 'main-menu-open' ) {
                self.isMainMenuActive = false;
            }
            else if( latest === 'user-menu-open' ) {
                self.isUserMenuActive = false;
            }
        });


        if (this.user_logged_in) {
            this.$echo.private('App.Models.User.' + this.$store.state.user.id)
                .notification((notification) => {
                    if (notification.type.includes('notification.')) {
                        this.$d('NOTIFICATION RECEIVED', notification);
                        this.$store.commit('notification', notification);
                        this.xHasUnreadNotifications = true;
                    }
                });
        }

        this.handleResize();
    },
    methods: {
        openNotifications: function() {
            if(this.$windowIs('<=', 'tablet')) {
                this.$addPopEvent('notifications-open');
                document.documentElement.style.overflow = 'hidden';
            }
            this.loadingNotifications = true;


            this.$http.post(this.url.notificationsFetch)
                .then(response => {
                    this.$d('response', response);
                    if (response.data.hasErrors) {
                        this.$store.commit('errors', response.data.errors);
                    } else if (response.data.success === true) {
                        this.xNotifications = response.data.notifications;
                        this.remainingUnread = response.data.remainingUnread;
                        this.xHasUnreadNotifications = this.remainingUnread > 0;
                    } else {
                        this.$store.commit('error', response.data.message);
                    }
                    this.loadingNotifications = false;
                })
                .catch(error => {
                    this.$displayError(error);
                    this.loadingNotifications = false;
                });

        },
        closeNotifications: function() {
            this.xNotifications = [];
            this.remainingUnread = 0;
            this.loadingNotifications = false;
            if(this.$windowIs('<=', 'tablet')) {
                this.$removePopEvent('notifications-open');
            }

            document.documentElement.style.overflow = 'auto';
        },
        openMainMenu: function() {
            if(this.$windowIs('<=', 'tablet')) {
                this.$addPopEvent('main-menu-open');
            }
        },
        closeMainMenu: function() {
            if(this.$windowIs('<=', 'tablet')) {
                this.$removePopEvent('main-menu-open');
            }
        },
        openUserMenu: function() {
            if(this.$windowIs('<=', 'tablet')) {
                this.$addPopEvent('user-menu-open');
            }
        },
        closeUserMenu: function() {
            if(this.$windowIs('<=', 'tablet')) {
                this.$removePopEvent('user-menu-open');
            }
        },

        handleResize: function() {
            this.window.width = window.innerWidth;

            if(!this.waitingOnResize && this.$windowIs('>', 'tablet')) {
            // if(this.$windowIs('>', 'tablet')) {
                this.waitingOnResize = true;
                this.calculateShrinking();
            }
        },
        calculateShrinking: function() {
            this.checking = setInterval(() => {
                this.forceShrink = false;

                if(this.$refs.navbarLeft && this.$refs.navbarRight && this.$refs.navbarRight.offsetWidth > 0 && this.$refs.navbarLeft.offsetWidth > 0) {
                    this.forceShrink = (this.$refs.navbarLeft.offsetWidth + this.$refs.navbarRight.offsetWidth) > this.window.width;
                    this.waitingOnResize = false;
                    clearInterval(this.checking);
                }
                else if(this.$windowIs('<=', 'tablet')) {
                    this.waitingOnResize = false;
                    clearInterval(this.checking);
                }
            }, 500);
        },

        toggleNotification: function(e) {
            if(this.isUserMenuActive) this.isUserMenuActive = false;
            if(this.isMainMenuActive) this.isMainMenuActive = false;
            this.notificationsOpen = !this.notificationsOpen;
        },
        toggleMainMenu: function (e) {
            if(this.isUserMenuActive) this.isUserMenuActive = false;
            if(this.notificationsOpen) this.notificationsOpen = false;
            this.isMainMenuActive = !this.isMainMenuActive;
        },
        toggleUserMenu: function (e) {
            if(this.isMainMenuActive) this.isMainMenuActive = false;
            if(this.notificationsOpen) this.notificationsOpen = false;
            this.isUserMenuActive = !this.isUserMenuActive;
        },
        toggleCollapse: function(target) {
            const index = this.expanded.indexOf(target);
            if (index >= 0)
                this.expanded.splice(index,1);
            else {
                this.expanded.push(target);
            }
        },
        popOutPlayer: function() {
            if( ! this.playingWindow || this.playingWindow.closed ) {
                this.playingWindow = window.open('http://player.live365.com/a25079?l', 'Xerocraft\'s KMKR 99.9 FM', "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, width=200, height=200");
            } else {
                this.playingWindow.close();
            }
        }
    },
}
</script>

<style scoped lang="scss">
.navbar-arrow {
    display: inline-block;
    margin-left: 0.5em;
    transition: all 0.25s ease;
}

.has-unread::after {
    content: '';
    position: absolute;
    bottom: 16px;
    right: 7px;
    height: 7px;
    width: 7px;
    background-color: red;
    border-radius: 50%;
}

.mobile-menus-wrapper {
    color: #fff;
    margin-left: auto;
    position: relative;
    display: flex;
}

.user-menu-button {
    display: inline-flex !important;
    height: 3.25rem;
    width: 3.25rem;
    align-items: center;
}

.user-menu-button span {
    opacity: 0;
}

.user-menu-button div.avatar {
    border: 1px solid #fff;
    border-radius: 14px;
    display: block;
    height: 28px;
    width: 28px;
    margin: 0 auto;
    overflow: hidden;
}
.user-menu-button div.avatar div {
    width: 100%;
    height: 100%;
    border-radius: 14px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    transition: all 0.2s ease;
}

.user-menu-button.is-active {
    background-color: transparent;
}

.user-menu-button.is-active div.avatar div {
    opacity: 0;
}

.user-menu-button.is-active span {
    opacity: 1;
}

.user-menu-button.is-active span:nth-child(2) {
    opacity: 0;
}

.navbar-burger {
    display: inline-block;
}

.user-dropdown {
    right: 0;
    left: auto;
}

@media screen and (min-width: 1024px) {
    .navbar:not(.force-shrink-nav) #userDropdownMenu {
        display: none;
    }

    .navbar:not(.force-shrink-nav) .user-menu-button.is-active div.avatar div {
        background-size: cover;
        opacity: 1;
    }

    .navbar:not(.force-shrink-nav) .is-hoverable:hover .navbar-arrow {
        transform: rotate(180deg);
    }

}

@media screen and (max-width: 1023px) {
    .has-dropdown .navbar-dropdown {
        padding-top: 0;
        padding-bottom: 0;
    }
    .navbar-arrow {
        float:right;
        margin-right:0;
    }

    .navbar-arrow.is-open {
        transform: rotate(180deg);
    }
}



.notification-side-wrapper {
    position: fixed;
    left:100%;
    top:0;
    background: white;
    z-index: 200;

    width: 100vw;
    height: 100vh;

    transition: left 0.25s ease;

    &.notifications-open {
        left: 0;
    }

    & .notification-header {
        height: 3.25rem;
        position: relative;
        text-align: left;
        padding: 0 1rem;
        display: flex;
        align-items: center;

        & div {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 1.25em;
        }

        & span {
            display: inline;
        }
    }


    .notifications-inner-wrapper {
        position: relative;
        width: 100%;
        height: calc(100% - 3.25rem);
    }
    .notifications-content {
        overflow-y: auto;
    }

    .is-unread {
        border: 1px solid #dbdbdb;
    }
}

.notification-modal-wrapper {
    position: absolute;
    right:0;
    top: calc(3.25rem - 15px);
    height: calc(95vh - 3.25rem + 15px);
    background: white;
    z-index: 200;
    width: 500px;

    .notifications-inner-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .notifications-content {
        overflow-y: auto;
    }
    .is-unread {
        border: 1px solid #dbdbdb;
    }
}

.navbar-menu {
    z-index: 199;
    position: relative;
}

.menu-open-overlay {
    position: fixed;
    top:52px;
    right:0;
    bottom: 0;
    left: 0;
    z-index: 198;
}

.notifications-loading {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;

    & div {
        display: inline-block;
        margin: 2rem auto;

        & p {
            background: white;
            border: 1px solid #d7d7d7;
            border-radius: 50%;
            overflow: hidden;

            & img {
                max-height: none;
            }
        }
    }
}

body .glowing-on-air {
    animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
    from {
        text-shadow: 0 0 4px #ee8383, 0 0 10px #ee8383, 0 0 18px #ff0000, 0 0 22px #ff0000, 0 0 24px #ff0000, 0 0 26px #ff0000, 0 0 28px #ff0000;
        color: #fff;
    }
    to {
        text-shadow: 0 0 4px #ee8383, 0 0 10px #ee8383, 0 0 18px #ff5f5f, 0 0 22px #ff5f5f, 0 0 24px #ff0000, 0 0 26px #ff0000, 0 0 28px #ff5f5f;
        color: #ff6a6a;
    }
}

.force-shrink-nav {
    &.navbar {
        display: block;
    }

    & .navbar-menu {
        max-height: calc(100vh - 3.25rem);
        overflow: auto;
        display:none;
        background-color: white;
        box-shadow: 0 8px 16px rgb(10 10 10 / 10%);
        padding: 0.5rem 0;
    }

    &.navbar.is-dark {
        color: #fff;
    }

    .has-dropdown .navbar-dropdown {
        padding-top: 0;
        padding-bottom: 0;
    }
    .navbar-arrow {
        float:right;
        margin-right:0;
    }

    .navbar-arrow.is-open {
        transform: rotate(180deg);
    }

    &.navbar.is-dark .navbar-start .navbar-item, &.navbar .navbar-start .navbar-link, &.navbar .navbar-end .navbar-link, &.navbar.is-dark .navbar-end .navbar-item {
        color: #4a4a4a;
        display: block;
        line-height: 1.5;
        padding: 0.5rem 0.75rem;
        position: relative;
        cursor: pointer;

        font-size: 1em !important;
    }

    &.navbar.is-dark .navbar-item.has-dropdown:hover .navbar-link {
        color: #4a4a4a;
    }

    &.navbar.is-dark.is-transparent .navbar-start a.navbar-item:hover, &.navbar.is-dark.is-transparent .navbar-start .navbar-link:hover,  &.navbar.is-dark.is-transparent .navbar-end a.navbar-item:hover, &.navbar.is-dark.is-transparent .navbar-end .navbar-link:hover {
        background-color: #fafafa !important;
        color: #3273dc;
    }

    &.navbar .navbar-start .navbar-link:hover {
        background-color: #fafafa;
        color: #3273dc;
    }

    &.navbar.is-dark .navbar-item.has-dropdown {
        padding: 0;
    }

    & .navbar-brand .navbar-item, .navbar-tabs .navbar-item {
        align-items: center;
        display: flex;
    }

    & .navbar-menu.is-active {
        display: block
    }

    &.navbar .navbar-start {
        display: block;
    }

    &.navbar .navbar-dropdown.is-boxed {
        border: none;
        box-shadow: none;
        display: block;
        opacity: 1;
        z-index: 20;
        position: relative;
        transform: none;

        & .navbar-item {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            display: block;
        }

        & .navbar-divider {
            display: none;
        }
    }

    &.navbar .navbar-end {
        display: block;
    }

    &.navbar .button.is-light:hover {
        background-color: whitesmoke;
        border-color: transparent;
        color: rgba(0, 0, 0, 0.7);

        &:hover {
            background-color: #eeeeee !important;
        }
    }

    &.navbar .button.is-primary {
        background-color: #f38135;
        border-color: transparent;
        color: #fff;

        &:hover {
            background-color: #f27929 !important;
            color: #fff !important;
        }
    }

    &.navbar .navbar-dropdown.is-boxed {
        pointer-events: initial;
    }


}

</style>
